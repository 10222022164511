import produce from 'immer';
import { map, tap } from 'rxjs';
import { Inject, inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { EntityListState, Query, QueryBuilder } from '@supy/common';
import { PageService, SnackbarService } from '@supy/components';
import { BaseChannel, WebSocketApp, WebSocketClient } from '@supy/websockets';

import {
  NOTIFICATION_STATE_TOKEN,
  NotificationFilters,
  NotificationGenerateReportResponse,
  NotificationQueryParams,
  NotificationStateModel,
  PortalNotification,
} from '../../core';
import { NotificationService, NotificationsStorageService } from '../../services';
import {
  NotificationDisposeReportsGenerationListener,
  NotificationGetMany,
  NotificationInitFilters,
  NotificationPatchFilter,
  NotificationPatchRequestMeta,
  NotificationResetFilter,
  NotificationSetFilter,
  NotificationSetReportsGenerationListener,
} from '../actions';

const FILTERS_DEFAULT = {
  status: null,
  branches: [],
  start: undefined,
  end: undefined,
  suppliers: [],
  sorting: null,
  documentNumber: null,
  retailerId: null,
  selectedStatistics: null,
  lockedOnly: false,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 50, channelIds: [], retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<NotificationStateModel>({
  name: NOTIFICATION_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    exportLoading: false,
  },
})
@Injectable()
export class NotificationsState extends EntityListState {
  private readonly notificationService = inject(NotificationService);
  private readonly pageService = inject(PageService);
  private readonly snackbarService = inject(SnackbarService);
  private readonly notificationsStorageService = inject(NotificationsStorageService);
  private generateReportChannel: BaseChannel;

  constructor(@Inject(WebSocketApp.Default) private readonly websocketClient: WebSocketClient) {
    super();
  }

  @Selector()
  static notifications(state: NotificationStateModel) {
    return EntityListState.all(state) ?? [];
  }

  @Selector()
  static filters(state: NotificationStateModel) {
    return EntityListState.currentFilters<NotificationFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: NotificationStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static requestMetadata(state: NotificationStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: NotificationStateModel) {
    return state.responseMetadata;
  }

  @Action(NotificationInitFilters)
  notificationsInitFilter(ctx: StateContext<NotificationStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(NotificationSetFilter)
  setNotificationsFilter(ctx: StateContext<NotificationStateModel>, action: NotificationSetFilter) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new NotificationPatchRequestMeta({ page: 0 }), new NotificationGetMany()]);
  }

  @Action(NotificationPatchFilter)
  patchNotificationsFilter(ctx: StateContext<NotificationStateModel>, action: NotificationPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new NotificationPatchRequestMeta({ page: 0 }), new NotificationGetMany()]);
  }

  @Action(NotificationResetFilter)
  resetNotificationsFilter(ctx: StateContext<NotificationStateModel>, { payload }: NotificationResetFilter) {
    const filters = {
      ...FILTERS_DEFAULT,
      ...payload,
    };

    super.resetFilter(ctx, filters);
    ctx.dispatch([new NotificationPatchRequestMeta({ page: 0 }), new NotificationGetMany()]);
  }

  @Action(NotificationGetMany, { cancelUncompleted: true })
  getNotifications(ctx: StateContext<NotificationStateModel>) {
    return this.notificationService.getNotifications(this.getQuery(ctx.getState())).pipe(
      map(({ data, metadata }) => ({
        data: data.map(grn => new PortalNotification(grn)),
        metadata,
      })),
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(NotificationPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<NotificationStateModel>, { payload }: NotificationPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(NotificationSetReportsGenerationListener)
  setReportsGenerationListener(
    ctx: StateContext<NotificationStateModel>,
    { userId }: NotificationSetReportsGenerationListener,
  ) {
    const channelName = 'report-generate-workflow';
    const initializeEventName = `${channelName}.initialize`;
    const successEventName = `${channelName}.success`;
    const errorEventName = `${channelName}.error`;

    if (this.generateReportChannel) {
      this.generateReportChannel.dispose();
    }

    this.generateReportChannel = this.websocketClient.subscribe(`private-cache-${channelName}-${userId}`);

    this.generateReportChannel.on(initializeEventName, (payload: NotificationGenerateReportResponse) => {
      const { date, uuid, message } = payload;

      const { message: notificationMessage, retailerId } = message ?? {};

      if (!message) {
        return;
      }

      const notification = new PortalNotification({
        id: uuid,
        module: 'Reports',
        detail: notificationMessage,
        event: 'Export initialized',
        time: (date && new Date(date)) || new Date(),
        status: 'info',
        retailerId,
      });

      if (NotificationsState.getIndex(notification.id, NotificationsState.notifications(ctx.getState())) === -1) {
        const notifications = [notification, ...NotificationsState.notifications(ctx.getState())];

        this.setMany(ctx, notifications.slice(0, 40));
        this.notificationsStorageService.setNotifications(ctx.getState());

        this.pageService.setHasNewNotifications(true);

        if (message) {
          this.snackbarService.success(notificationMessage);
        }
      }
    });

    this.generateReportChannel.on(successEventName, (payload: NotificationGenerateReportResponse) => {
      const { date, uuid, message } = payload;

      const { message: notificationMessage, reportUrl, retailerId } = message ?? {};

      if (!message) {
        return;
      }

      const newNotification = new PortalNotification({
        id: uuid,
        module: 'Reports',
        detail: notificationMessage,
        event: `Report generated`,
        time: new Date(date),
        status: 'info',
        action: reportUrl,
        retailerId,
      });

      const notifications = NotificationsState.notifications(ctx.getState());
      const existingNotification = notifications.find(({ id }) => id === newNotification.id);

      if (existingNotification?.event === newNotification.event) return;

      let updatedNotifications = Array.from(notifications);

      if (!existingNotification) {
        updatedNotifications.unshift(newNotification);
      } else {
        updatedNotifications = updatedNotifications.map(notification =>
          notification.id === newNotification.id ? newNotification : notification,
        );
      }

      this.setMany(ctx, updatedNotifications.slice(0, 40));
      this.notificationsStorageService.setNotifications(ctx.getState());
      this.pageService.setHasNewNotifications(true);

      if (message) {
        this.snackbarService.success(notificationMessage);
      }
    });

    this.generateReportChannel.on(errorEventName, (payload: NotificationGenerateReportResponse) => {
      const { date, uuid, message } = payload;

      const { message: notificationMessage, title, retailerId } = message ?? {};

      if (!message) {
        return;
      }

      const newNotification = new PortalNotification({
        id: uuid,
        module: 'Reports',
        detail: notificationMessage,
        event: title,
        time: new Date(date),
        status: 'error',
        retailerId,
      });

      const notifications = NotificationsState.notifications(ctx.getState());
      const existingNotification = notifications.find(({ id }) => id === newNotification.id);

      if (existingNotification?.event === newNotification.event) return;

      let updatedNotifications = Array.from(notifications);

      if (!existingNotification) {
        updatedNotifications.unshift(newNotification);
      } else {
        updatedNotifications = updatedNotifications.map(notification =>
          notification.id === newNotification.id ? newNotification : notification,
        );
      }

      this.setMany(ctx, updatedNotifications.slice(0, 40));
      this.notificationsStorageService.setNotifications(ctx.getState());
      this.pageService.setHasNewNotifications(true);

      if (message) {
        this.snackbarService.open(notificationMessage, { variant: 'error' });
      }
    });

    this.generateReportChannel.on(
      'pusher:cache_miss',
      (payload: string | { event: string; data: string | NotificationGenerateReportResponse }) => {
        if (payload) {
          const missedData =
            typeof payload === 'string'
              ? (JSON.parse(payload) as {
                  event: string;
                  data: string | NotificationGenerateReportResponse;
                })
              : payload;

          if (missedData.data) {
            const data: NotificationGenerateReportResponse =
              typeof missedData.data === 'string'
                ? (JSON.parse(missedData.data) as NotificationGenerateReportResponse)
                : missedData.data;

            this.generateReportChannel.emit(missedData.event, data);
          }
        }
      },
    );
  }

  @Action(NotificationDisposeReportsGenerationListener)
  disposeReportsGenerationListener() {
    this.generateReportChannel?.dispose();
  }

  private getQuery(
    state: NotificationStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<NotificationQueryParams> {
    const { branches, date, status, suppliers } = state.filters;

    const qb = new QueryBuilder<NotificationQueryParams>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: 'time',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (branches?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: branches,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: suppliers,
        op: 'in',
      });
    }

    if (date) {
      // TODO: fix date filtering to pass start and end of the day as lt and gt
      qb.filtering.withFiltering([{ by: 'time', op: 'eq', match: new Date(date) }]);
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    return qb.build();
  }
}
